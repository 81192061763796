<template>
  <div>
    <v-app-bar color="#1976d2" dark class="hidden-sm-and-up">
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title>EventsMV</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn small icon>
        <v-icon light color="white">mdi-magnify</v-icon>
      </v-btn>

      <v-btn small icon color="white">
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-btn>

      <v-menu rounded="lg" offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-avatar size="24" class="ml-2" v-bind="attrs" v-on="on">
            <img src="../../assets/face.png" />
          </v-avatar>
        </template>
        <v-list dense>
          <v-list-item-group v-model="selectedMenu" color="primary">
            <v-list-item v-for="menu in userMenus" :key="menu.id">
              <v-list-item-content>
                <v-list-item-title v-text="menu.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  v-text="'Log Out'"
                  @click="signOut"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-group
          v-for="m in menu"
          :key="m.id"
          v-model="m.active"
          no-action
          sub-group
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ m.module }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-group
            v-for="s in m.SubModules"
            :key="s.id"
            v-model="s.active"
            no-action
            sub-group
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title @click="gotoLink(s)">
                  {{ s.module }}
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-for="l in s.SubModules" :key="l.id" link>
              <v-list-item-title v-text="l.module"></v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  methods: {
    gotoLink(link) {
      this.$router.push(link.route_path);
    },
  },
  name: "MobileHeader",
  data() {
    return {
      drawer: false,
      menu: [],
    };
  },
};
</script>
<style>
.v-toolbar__extension {
  background: #3c9bf9 !important;
}
</style>
